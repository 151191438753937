import ImageSet from "../atoms/ImageSet"
import { useRef } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';

import * as Padding from "../../utils/Padding"
import { useState, useEffect } from 'react';
import { useInView } from "react-intersection-observer";
import { motion, MotionConfig } from "framer-motion";
import SwiperCore, {
    Navigation, Pagination, Mousewheel, Keyboard, Autoplay
} from 'swiper/core';

SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard, Autoplay]);


export default function Slider({ blok }) {
    function useWindowSize() {
        const [windowSize, setWindowSize] = useState({
            width: undefined,
        });
        useEffect(() => {
            if (typeof window !== 'undefined') {
                function handleResize() {
                    setWindowSize({
                        width: window.innerWidth,
                    });
                }
                window.addEventListener("resize", handleResize);
                handleResize();
                return () => window.removeEventListener("resize", handleResize);
            }
        }, []);
        return windowSize;
    }
    const swiperRef = useRef();
    const size = useWindowSize();

    let slidesOffsetBefore = []

    size.width < 768 ? slidesOffsetBefore = 0 : slidesOffsetBefore = Math.floor(size.width / 2)

    const [ref, inView, entry] = useInView({
        threshold: 0.01,
        triggerOnce: true,
    })
    return (
        <div ref={ref} className={`w-full h-[66vh] relative`}>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                    type: "easeIn",
                    duration: 1,
                    delay: 0.5
                }}
            >
                <Swiper
                    ref={swiperRef}
                    init
                    speed={1000}
                    cssMode={false}
                    observer
                    resizeObserver
                    mousewheel={false}
                    preloadImages
                    freeMode={false}
                    loop
                    keyboard
                    spaceBetween={16}
                    slidesOffsetBefore={16}
                    grabCursor
                    slidesPerView={"auto"}
                    breakpoints={{
                        768: {
                            loop: true,
                            spaceBetween: 24,
                        },
                        1028: {
                            loop: true,
                            spaceBetween: 24

                        },
                    }}
                >
                    {blok.images.map((image, i) => {
                        return (
                            <SwiperSlide key={`Swiper-Slide-Infos-${i}`}>
                                <div className="h-[66vh] w-auto">
                                    <ImageSet css="w-auto h-[66vh] object-contain" image={image} />
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </motion.div>
        </div >
    )
}