import DynamicComponent from "../molecules/DynamicComponent"
import SbEditable from "storyblok-react"
import Link from "next/link"
import TextPost from "../molecules/TextPost"
import Slider from "../molecules/Slider"
import * as Padding from "../../utils/Padding"

export default function Journal({ blok }) {
    return (
        <>
            {blok.posts ? blok.posts.map((post, i) => {
                return (
                    <div key={`journal-post-content-${i}`}>
                        {
                            post.content.body.map((singleblok, i) => {
                                return (
                                    <div className=" w-full flex flex-wrap justify-center" key={`journal-singleblok-${i}`} >
                                        {i < 2 ?
                                            singleblok.component == "TextPost" ? (
                                                <TextPost slug={`/${post.default_full_slug}`} short blok={singleblok} />
                                            ) : null
                                                ||
                                                singleblok.component == "Slider" ? (
                                                <div className="w-full flex justify-end">
                                                    <div className="w-full lg:w-1/2">
                                                        <Slider blok={singleblok} key={`journal-singleblok-${i}`} />
                                                    </div>
                                                </div>
                                            ) : null
                                            : null}
                                    </div>
                                )
                            })
                        } < br />
                        <section>
                            <div className=" w-full flex flex-wrap justify-center">
                                <div className={`w-full flex  lg:w-3/5 justify-start lg:justify-end ${Padding.gap}`}>
                                    <div className="w-0 lg:w-1/2"></div>
                                    <div className="lg:w-1/2 text-left underline">
                                        <Link href={`/${post.default_full_slug}`}>
                                            <a>
                                                More
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                )
            }
            ) : null}
        </>
    )
}