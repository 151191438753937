
import * as Padding from "../../utils/Padding"
import Heading from "../atoms/Heading"
import Text from "../atoms/Text"
import Link from "next/link"
import Slider from "./Slider"
import { useInView } from "react-intersection-observer"
import { framer, motion } from "framer-motion"
import { textShorter } from "../../utils/textShorter"

export default function LandingInfos({ blok }) {
    const [ref, inView, entry] = useInView({
        threshold: 0.2,
        triggerOnce: false,
    })
    return (
        <div ref={ref} >
            <motion.div
                className="w-full flex flex-wrap"
                initial={{ y: "50px" }}
                animate={{ y: inView ? "0px" : "64px" }}
                transition={{
                    type: "easeIn",
                    duration: 1,
                    delay: 0.5
                }}
            >
                <div className="w-full lg:w-1/2">
                    <section>
                        <Text pTag="copy">
                            {textShorter(blok.content.body[0].text, 259)} <br />…
                            <div className="pt-12  underline">
                                <Link href="/infos">
                                    <a>
                                        More Infos
                                    </a>
                                </Link>
                            </div>
                        </Text>
                    </section>
                </div>
                <div className="w-full lg:w-1/2 pt-12 lg:pt-0">
                    <Slider blok={blok.content.body[1]} />
                </div>
            </motion.div>
        </div>
    )
}