import DynamicComponent from "../molecules/DynamicComponent";
import { useRouter } from "next/dist/client/router";
import Link from "next/link";
import ShopItem from "../molecules/ShopItem";
import * as Padding from "../../utils/Padding";
import Text from "../atoms/Text";
import { useInView } from "react-intersection-observer";
import { framer, motion } from "framer-motion";

export default function ShopGrid({ products }) {
  const router = useRouter();
  const [ref, inView, entry] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  return (

    <div ref={ref} className="w-full">
      <motion.div
        initial={{ y: "50px" }}
        animate={{ y: inView ? "0px" : "64px" }}
        transition={{
          type: "easeIn",
          duration: 1,
          delay: 0.5,
        }}
      >
        <div className="sectionm">
          <div className={`${Padding.paddingY}`}>
            <div className={`w-full  grid ${Padding.gap}  grid-cols-1 lg:grid-cols-2 `}>
              {products
                ? products.map((item, i) => {
                  return <ShopItem blok={item.content} story={item} key={`item-${i}`} />;
                })
                : null}
              {router.route != "/shop" ? (
                <div className="w-full flex items-center justify-center underline h-[25vh] lg:h-auto">
                  <Text pTag="copy">
                    <Link href="/shop">
                      <a>All Products</a>
                    </Link>
                  </Text>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </motion.div>
    </div>

  );
}
