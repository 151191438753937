import Text from "../atoms/Text"
import * as Padding from "../../utils/Padding"
import Link from "next/link"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"
import { textShorter } from "../../utils/textShorter"

export default function TextPost({ blok, slug, short = false }) {
    const [ref, inView, entry] = useInView({
        threshold: 0.01,
        triggerOnce: false,
    })
    return (
        <div ref={ref} className={`w-full flex  lg:w-3/5 ${Padding.paddingY}`}>
            <section>
                <motion.div
                    initial={{ y: "64px" }}
                    animate={{ y: "0px" }}
                    transition={{
                        type: "easeIn",
                        duration: 1
                    }}
                >
                    <div className={`w-full flex flex-wrap lg:flex-nowrap  ${Padding.gap} ${Padding.paddingYQuarter}`}>
                        <div className="w-full lg:w-1/2 ">
                            <Text pTag="para">
                                {blok.title}<br />
                                <span className="text-[#AAAAAA]">{blok.person}</span>
                            </Text>
                        </div>
                        <div className="w-full lg:w-1/2 whitespace-pre-wrap">
                            <Text pTag="para">
                                {short ? <>{textShorter(blok.text, 250)} …</> : blok.text}
                            </Text>
                        </div>
                    </div>
                </motion.div>
            </section>
        </div>
    )
}