import DynamicComponent from "../molecules/DynamicComponent";
import SbEditable from "storyblok-react";
import ShopText from "../molecules/ShopText";
import ShopGrid from "../organisms/ShopGrid";

export default function Shop({ blok, products }) {
  return (
    <>
      <div className="min-h-screen">
        <ShopText blok={blok.body[0]} />

        <ShopGrid products={products} />

      </div>
    </>
  );
}
