import DynamicComponent from "../molecules/DynamicComponent"
import SbEditable from "storyblok-react"

export default function JournalPost({ blok }) {
    return (
        <>
            <div className="min-h-screen w-full flex flex-wrap justify-center">
                <SbEditable content={blok}>
                    {blok.body ? blok.body.map((blok) =>
                        <DynamicComponent blok={blok} key={blok._uid} />
                    ) : null}
                </SbEditable>
            </div>
        </>
    )
}