
import ImageSet from "../atoms/ImageSet";
import * as Padding from "../../utils/Padding";
import Heading from "../atoms/Heading";
import Text from "../atoms/Text";
import { framer, motion } from "framer-motion"
import { transformImage } from "../../utils/transformImage"

export default function Product({ blok, story }) {
  return (
    <motion.div
      initial={{ y: "64px" }}
      animate={{ y: "0px" }}
      transition={{
        type: "easeIn",
        duration: 1
      }}
    >
      <div className="min-h-screen ">
        <section>
          {!blok.sold ? (
            <div className={`w-full relative flex flex-wrap lg:flex-nowrap ${Padding.gap}`}>
              <div className="w-full lg:w-1/2 order-2 lg:order-1">
                {blok.images &&
                  blok.images.map((image, i) => {
                    return (
                      <div className="pb-4 md:pb-6 select-none" key={`ProductImage-${i}`}>
                        <ImageSet image={image} zoom="true" />
                      </div>
                    );
                  })}
              </div>
              <div className="w-full lg:w-1/2  lg:sticky  lg:px-24  items-center py-8 lg:h-screen flex top-16 order-1 lg:order-2">
                <div className="">
                  <Heading>{blok.name ? <>{blok.name}</> : null}</Heading>
                  <div className="pt-8">
                    <Text pTag="small">{blok.description ? <>{blok.description}</> : null}</Text>
                  </div>
                  <div className="pt-8">
                    <Text pTag="small">
                      <span className="text-[#AAAAAA] leading-relaxed">
                        {blok.color.name ? <>Color: {blok.color.name}<br /></> : null}
                        {blok.type.name ? <>Type: {blok.type.name}<br /></> : null}
                        {blok.category.name ? <>Category: {blok.category.name}<br /></> : null}
                        <br />
                        {blok.diameter ? <>Diameter: {blok.diameter} mm <br /></> : null}
                        {blok.height ? <>Height: {blok.height} mm <br /></> : null}
                        {blok.weight ? <>Weight: {blok.weight} g <br /></> : null} <br />
                      </span>
                    </Text>
                  </div>
                  <div className="w-full items-center flex space-x-6 pt-24">
                    {!blok.sold ? (
                      <>
                        <Text pTag="copy">{blok.price ? <>{blok.price}</> : null} €</Text>
                        <button
                          data-autopop="false"
                          className="py-3 px-6 rounded-lg border border-black hover:bg-black hover:text-white duration-300 cursor-pointer snipcart-add-item"
                          data-item-id={story.id}
                          data-item-price={blok.price}
                          data-item-url={`/shop/product/${story.slug}`}
                          //data-item-weight={blok.weight}
                          data-item-description={blok.description}
                          data-item-image={blok.images[0] && transformImage(blok.images[0].filename, "0x750", "60")}
                          data-item-name={blok.name}
                          data-item-max-quantity="1"
                        >
                          add to cart
                        </button>
                      </>
                    ) : (
                      <>
                        <Text pTag="copy">sold</Text>
                        <button className="py-3 px-6 rounded-lg border border-black hover:bg-black hover:text-white duration-300 cursor-pointer snipcart-add-item">
                          sold
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full  items-center justify-center h-screen flex">
              <Heading hTag="h1">This product was sold</Heading>
            </div>
          )}
        </section>
      </div>
    </motion.div>
  );
}
