import DynamicComponent from "../molecules/DynamicComponent"
import SbEditable from "storyblok-react"
import Navigation from "../organisms/Navigation"
import Footer from "../organisms/Footer"
import HeroImage from "../molecules/HeroImage"
import LandingInfos from "../molecules/LandingInfos"
import ShopGrid from "../organisms/ShopGrid"

export default function Landing({ blok }) {
    return (
        <div id="home" className="min-h-screen bg-white z-50">
            <SbEditable content={blok}>
                {blok.body ? blok.body.map((blok) =>
                    <DynamicComponent blok={blok} key={blok._uid} />
                ) : null}
            </SbEditable>

            <ShopGrid products={blok.shop[0].items} />

            <LandingInfos blok={blok.infos} />
        </div>
    )
}