import Text from "../atoms/Text";
import * as Padding from "../../utils/Padding";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

export default function ShopText({ blok }) {
  const [ref, inView, entry] = useInView({
    threshold: 0.6,
    triggerOnce: false,
  });
  return (
    <div ref={ref}>
      <section>
        <motion.div
          initial={{ y: "64px" }}
          animate={{ y: "0px" }}
          transition={{
            type: "easeIn",
            duration: 1,
          }}
        >
          <div className={`w-full  whitespace-pre-wrap flex ${Padding.gap} ${Padding.paddingY}`}>
            <div className="w-full lg:w-1/2 lg:pr-8">
              <Text pTag="copy">{blok.text}</Text>
            </div>
          </div>
        </motion.div>
      </section>
    </div>
  );
}
