import RichText from "../atoms/RichText";

export default function LegalPage({ blok }) {

    return (
        <div className="min-h-screen w-full lg:w-1/2">
            <section>
                <RichText >
                    {blok.text}
                </RichText>
            </section>
        </div>
    )
}