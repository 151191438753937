
import Navigation from './Navigation'
import Footer from './Footer'


const Layout = ({ children }) => (
    <>
        {children}
    </>
)

export default Layout