import React from "react"
import { render, MARK_LINK } from "storyblok-rich-text-react-renderer"

export default function RichText({ children }) {
  return (
    <article className="prose-xl">
      <br /><br />{render(children)}
      <style global jsx >{`
        .prose-xl h1,h2,h3,h4,h5,h6,li,ul,ol  {
          font-size: 18px;
        }
        .prose-xl  h1,h2,h3,h4,h5,h6 {
            text-transform: uppercase;
        }
        .prose-xl p {
          margin-top: 0;
          font-size: 18px;
          margin-bottom: 2;
        }
        .prose-xl ul > li {
          padding-left: 0;
        }
        .prose-xl li {
          margin-top:0;
          margin-bottom:0;
        }
        .prose-xl > ul > li > *:last-child {
          margin-bottom: 0;
        }
        .prose-xl > ul > li > *:first-child {
          margin-top: 0em;
        }
        .prose-xl > ul > li p {
          margin-top: 0;
          margin-bottom: 0;
        }
        ol, ul {
          list-style-position: inside;
        }
        li {
          list-style-position: inside;
          list-style: circle;
        }
        li > p {
          list-style-position: inside;
          list-style: dot;
          display: inline;
        }
      `}
      </style>
    </article>
  )
}





