import Layout from "../organisms/Layout";

import Text from "../atoms/Text";
import TextPost from "../molecules/TextPost";
import ShopText from "../molecules/ShopText";
import Slider from "../molecules/Slider";
import RichText from "../atoms/RichText";
import Heading from "../atoms/Heading";
import Navigation from "../organisms/Navigation";
import Footer from "../organisms/Footer";
import Shop from "../templates/Shop";
import Landing from "../templates/Landing";
import Journal from "../templates/Journal";
import LegalPage from "../templates/LegalPage";
import Infos from "../templates/Infos";
import SbEditable from "storyblok-react";
import ShopGrid from "../organisms/ShopGrid";
import Product from "../templates/Product";
import JournalPost from "../templates/JournalPost";

// resolve Storyblok components to Next.js components
const Components = {
  Layout: Layout,
  Navigation: Navigation,
  Footer: Footer,
  ShopGrid: ShopGrid,

  Text: Text,
  TextPost: TextPost,
  ShopText: ShopText,
  Slider: Slider,
  Heading: Heading,
  RichText: RichText,

  Shop: Shop,
  LegalPage: LegalPage,
  Landing: Landing,
  Infos: Infos,
  Journal: Journal,
  Product: Product,
  JournalPost: JournalPost,
};

const DynamicComponent = ({ blok }) => {
  // check if component is defined above
  if (typeof Components[blok.component] !== "undefined") {
    const Component = Components[blok.component];
    // wrap with SbEditable for visual editing
    return (
      <SbEditable content={blok}>
        <Component blok={blok} />
      </SbEditable>
    );
  }

  // fallback if the component doesn't exist
  return (
    <p>
      The component <strong>{blok.component}</strong> has not been created yet.
    </p>
  );
};

export default DynamicComponent;
