import Text from "../atoms/Text"
import ImageSet from "../atoms/ImageSet"
import Link from "next/link"
import { motion } from "framer-motion"

export default function ShopItem({ blok, story }) {
    return (
        <Link href={`/${story.default_full_slug}`
        }>
            <a>
                <div className="w-full bg-gray-100 h-auto lg:h-[60vh] flex items-center justify-center">
                    {blok.images[0] ?
                        !blok.sold ? (
                            <ImageSet css="w-full" image={blok.images[0] ? blok.images[0] : "empty"} />
                        ) : (
                            <ImageSet css="w-full opacity-50" image={blok.images[0] ? blok.images[0] : "empty"} />
                        ) : null}
                </div>
                <div className="pt-1 lg:pt-2 px-4 lg:px-0">
                    <Text pTag="small">
                        {blok.name ? <>{blok.name}<br /></> : null}
                        {blok.type.name ? <>{blok.type.name}<br /></> : null}
                        {!blok.sold ? (
                            <>
                                {blok.price} Euro<br />
                            </>
                        ) : (
                            <>
                                The product has already been sold
                            </>
                        )}
                    </Text>
                </div>
            </a>
        </Link>

    )
}